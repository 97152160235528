.joinForm {
  /*---------------------------------------------*/
  a {
      font-family: Poppins-Regular;
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  
  a:focus {
      outline: none !important;
  }
  
  a:hover {
      text-decoration: none;
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
  }
  
  /*---------------------------------------------*/
  h1,h2,h3,h4,h5,h6 {
      margin: 0px;
  }
  
  p {
      font-family: Roboto, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px;
  }
  
  ul, li {
      margin: 0px;
      list-style-type: none;
  }
  
  
  /*---------------------------------------------*/
  input {
      outline: none;
      border: none;
  }
  
  textarea {
    outline: none;
    border: none;
  }
  
  textarea:focus, input:focus {
    border-color: transparent !important;
  }
  
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
  
  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; }
  textarea:focus::-moz-placeholder { color:transparent; }
  textarea:focus:-ms-input-placeholder { color:transparent; }
  
  input::-webkit-input-placeholder { color: #adadad;}
  input:-moz-placeholder { color: #adadad;}
  input::-moz-placeholder { color: #adadad;}
  input:-ms-input-placeholder { color: #adadad;}
  
  textarea::-webkit-input-placeholder { color: #adadad;}
  textarea:-moz-placeholder { color: #adadad;}
  textarea::-moz-placeholder { color: #adadad;}
  textarea:-ms-input-placeholder { color: #adadad;}
  
  /*---------------------------------------------*/
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  
  iframe {
      border: none !important;
  }
  
  
  /*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
  .txt1 {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 13px;
    color: #666666;
    line-height: 1.5;
  }
  
  .txt2 {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 13px;
    color: #333333;
    line-height: 1.5;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ login ]*/
  
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  &.container-login100 {
    width: 100%;  
    min-height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f2f2f2;  
  }
  
  .wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
  
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  
  /*------------------------------------------------------------------
  [ Form ]*/
  
  .login100-form {
    width: 100%;
    text-align: center;
  }
  
  .login100-form-title {
    display: block;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
  }
  .login100-form-title i {
    font-size: 60px;
  }
  
  /*------------------------------------------------------------------
  [ Input ]*/
  
  .wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
  }
  
  .input100 {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
  
    display: block;
    text-align: center;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
  }
  
  /*---------------------------------------------*/ 
  .focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  .focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    background: #6a7dfe;
    background: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    background: -o-linear-gradient(left, #21d4fd, #b721ff);
    background: -moz-linear-gradient(left, #21d4fd, #b721ff);
    background: linear-gradient(left, #21d4fd, #b721ff);
  }
  
  .focus-input100::after {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 15px;
    color: #999999;
    line-height: 1.2;
  
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;
    padding-left: 5px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .input100:focus + .focus-input100::after {
    top: -15px;
  }
  
  .input100:focus + .focus-input100::before {
    width: 100%;
  }
  
  .has-val.input100 + .focus-input100::after {
    top: -15px;
  }
  
  .has-val.input100 + .focus-input100::before {
    width: 100%;
  }
  
  /*---------------------------------------------*/
  .btn-show-pass {
    font-size: 15px;
    color: #999999;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .btn-show-pass:hover {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
  }
  
  .btn-show-pass.active {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
  }
  
  
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
  }
  
  .wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;

    &:active:not(.disabled) {
      transform: scale(0.95);
    }
  }
  
  .login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #267e6e;
    top: 0;
    left: -100%;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    &.disabled {
      background-color: #709d95;
    }
  }
  
  .login100-form-btn {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
  }
  
  .wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
  }
}
